import { StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { RouterProvider, matchRoutes, createBrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider, hydrate as hydrateState, QueryClient } from '@tanstack/react-query';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';

import { Manager } from '@Events/Manager';
import { CompareListener } from '@Events/listeners/compare-listener';
import { WebPushListener } from '@Events/listeners/web-push-listener';
import { AnalyticsCartLoadListener } from '@Events/listeners/analytics-cart-load-listener';
import RequestProvider from '@Contexts/Request/RequestProvider';
import PageError from '@Pages/PageError';
import useGlobalConfig from '@Hooks/useGlobalConfig';
import { createRoutes } from '@Navigation/routes';
import { DIProvider } from '@Contexts/DI';
import DI from '@Domain/di';
import CabinetApi from '@Api/Cabinet';
import SentryBrowserLogger from '@Infrastructure/details/loggers/SentryBrowserLogger';
import AnalyticsEventDispatcher from '@Infrastructure/details/analytics/AnalyticsEventDispatcher';
import CalltouchService from '@Infrastructure/details/calltouch/CalltouchService';
import ErrorBoundary from '@Components/ErrorBoundary';

import type { Query } from '@Types/Base';

loadableReady(async () => {
  const globalConfig = useGlobalConfig();
  const root = document.getElementById('root');
  const state = window.__SERVER_STATE__;

  const loggers = {
    development: console,
    staging: console,
    production: new SentryBrowserLogger(globalConfig.sentry),
  };

  const query: Query = {
    origin: globalConfig.origin,
    proxyOrigin: globalConfig.proxyOrigin,
    country: globalConfig.country,
    region: globalConfig.region,
    secondaryRegion: globalConfig.secondaryRegion,
    allRegions: globalConfig.allRegions,
    mainRegion: globalConfig.mainRegion,
    language: globalConfig.language,
    supportedLanguages: globalConfig.supportedLanguages,
    defaultLanguage: globalConfig.defaultLanguage,
    pilotRegions: globalConfig.pilotRegions,
    status: { code: globalConfig.status.code || 200, message: globalConfig.status.message },
    queryClient: new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          retryOnMount: false,
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
        },
      },
    }),
  };

  const isCriticalError = query.status.code >= 500;

  const routes = createRoutes(query);

  // Determine if any of the initial routes are lazy
  const lazyMatches = matchRoutes(routes, window.location)?.filter((m) => m.route.lazy);

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy();

        Object.assign(m.route, {
          ...routeModule,
          lazy: undefined,
        });
      }),
    );
  }

  const logger = loggers[globalConfig.environment];

  const analytics = new AnalyticsEventDispatcher({
    analyticsUrl: globalConfig.analyticsUrl,
    logger,
  });

  const eventManager = new Manager({
    logger,
    listeners: [
      new CompareListener({
        queryClient: query.queryClient,
        analytics,
      }),
      new WebPushListener({
        country: query.country,
      }),
      new AnalyticsCartLoadListener({ analytics }),
    ],
  });

  DI.initDeps({
    eventManager,
    logger,
    cabinetApi: new CabinetApi({ country: query.country }),
    analytics,
    telephony: globalConfig.calltouchModId
      ? new CalltouchService({
          calltouchUrl: globalConfig.calltouchUrl,
          calltouchClientUrl: globalConfig.calltouchClientUrl,
          logger,
          modId: globalConfig.calltouchModId,
          country: query.country,
        })
      : null,
    cache: null,
  });

  const router = createBrowserRouter(routes);
  const tree = (
    <ErrorBoundary>
      <StrictMode>
        <DIProvider value={DI.getDeps()}>
          <HelmetProvider>
            <RequestProvider value={{ ...query }}>
              <QueryClientProvider client={query.queryClient}>
                <FlagsmithProvider
                  flagsmith={flagsmith}
                  serverState={globalConfig.flagsmith.serverState}
                >
                  {isCriticalError ? (
                    <PageError message='Critical error 500' />
                  ) : (
                    <RouterProvider
                      router={router}
                      fallbackElement={<PageError message=' Router fallback element' />}
                      future={{ v7_startTransition: true }}
                    />
                  )}
                </FlagsmithProvider>
              </QueryClientProvider>
            </RequestProvider>
          </HelmetProvider>
        </DIProvider>
      </StrictMode>
    </ErrorBoundary>
  );

  hydrateState(query.queryClient, state);
  hydrateRoot(root, tree);
});

if (module.hot) {
  module.hot.accept();
}
