import type { GlobalConfig } from '@Types/GlobalConfig';

export interface UseGlobalConfigResult extends GlobalConfig {
  isDevelopment: boolean;
  isProduction: boolean;
  isStaging: boolean;
}

/**
 * Позволяет получить доступ к глобальному конфигу приложения.
 * В данный момент работает только на клиенте (на сервере только сdnUrl)
 */
const useGlobalConfig = (): Partial<UseGlobalConfigResult> => {
  if (typeof window === 'undefined') {
    return {
      сdnUrl: process.env.CDN_URL || '',
      analyticsUrl: process.env.ANALYTICS_URL,
    };
  }

  const config = (window as any).__CONFIG__;

  return {
    ...config,
    isDevelopment: config.environment === 'development',
    isProduction: config.environment === 'production',
    isStaging: config.environment === 'staging',
  };
};

export default useGlobalConfig;
