import { cn } from '@divlab/divanui';

import useCountryBasedData from '@Hooks/useCountryBasedData';
import useRequest from '@Hooks/useRequest';
import Link from '@Navigation/Link';
import Logotype from '@Components/Logotype';
import styles from './HeaderMobile.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CountryBasedData } from '@Types/Base';

const siteNameData: CountryBasedData<string> = {
  RUS: 'divan.ru',
  BLR: 'divan.by',
  KAZ: 'mebel.kz',
  UZB: 'divan.uz',
};

const HeaderMobile: FC<HTMLAttributes<HTMLElement>> = (props) => {
  const { className, ...restProps } = props;
  const siteName = useCountryBasedData(siteNameData);
  const { country } = useRequest();

  return (
    <header className={cn(styles.header, className)} {...restProps}>
      <Link to='/' aria-label={siteName}>
        <Logotype country={country} />
      </Link>
    </header>
  );
};

export default HeaderMobile;
