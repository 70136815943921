function clarityInit(id) {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = `https://www.clarity.ms/tag/${i}`;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', id);
}

function clarity() {
  const params = Array.prototype.slice.call(arguments);

  if (!window.clarity) {
    throw new Error('Clarity is not initialized');
  }

  window.clarity.apply(undefined, params);
}

function init(id) {
  clarityInit(id);
}

function hasStarted() {
  return typeof window.clarity === 'function';
}

function identify(userId, properties) {
  clarity('identify', userId, properties);
}

function consent() {
  clarity('consent');
}

function setTag(key, value) {
  clarity('set', key, value);
}

function upgrade(reason) {
  clarity('upgrade', reason);
}

export default {
  init,
  hasStarted,
  identify,
  consent,
  setTag,
  upgrade,
};
