import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { UpdatedCartInfo } from '@Types/Cart';

export interface Params {
  cartPositionId: string;
}

export interface Result {
  result: 'success' | 'error';
  data: UpdatedCartInfo;
}

async function unhide(params: Params) {
  const url = '/backend/cart-2/unhide';
  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default unhide;
