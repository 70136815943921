import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData } from '@Types/Cart';

export const useRemoveBonus = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  const { mutate, isPending } = useMutation({
    mutationKey: ['removeBonuses'],
    mutationFn: async () => {
      const res = await ApiCart.sendBonus({
        'BonusPointsForm[value]': 0,
      });
      return res;
    },
    onSuccess: (NewCart) => {
      queryClient.setQueriesData({ queryKey: keys }, (prev: CartData) => {
        return {
          ...prev,
          ...NewCart,
        };
      });
    },
  });

  return {
    removeBonus: mutate,
    isPending,
  };
};

export default useRemoveBonus;
