import DIContext from './DIContext';

import type { FC, PropsWithChildren } from 'react';
import type { Deps } from '@Domain/di';

export interface DIProviderProps {
  value: Deps;
}

const DIProvider: FC<PropsWithChildren<DIProviderProps>> = (props) => {
  const { value, children } = props;

  return (
    <DIContext.Consumer>
      {(context) => (
        <DIContext.Provider value={{ ...context, ...value }}>{children}</DIContext.Provider>
      )}
    </DIContext.Consumer>
  );
};

export default DIProvider;
