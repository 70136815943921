import { createDerived, createStore, keepActive, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

import type { ProductData } from '@Types/Product';
import type { ListData } from '@Types/RelatedProducts';

export interface ChangeQuantityProductParams {
  productId: number;
  quantity: number;
}

export interface LoadProductListParams {
  listId?: number;
}

export interface Params {
  listId: 'warranty' | number;
}

export type ChangeQuantityProduct = (params: ChangeQuantityProductParams) => void;
export type LoadProductsList = (params?: LoadProductListParams) => Promise<void>;

export const relatedProductsStore = createStore<ListData[]>(() => {
  relatedProductsStore.set([]);
});

export const selectedStore = createDerived(relatedProductsStore, (relatedProducts) => {
  const products = [];
  relatedProducts.forEach((list) => products.push(list.products));

  return products.flat().filter((product) => product.quantity > 0);
});

export const init = (initValue: ListData[]) => {
  if (!initValue) return;

  update(relatedProductsStore, () => initValue);
};

export const clear = () => {
  update(relatedProductsStore, () => []);
};

export const sort = () => {
  update(relatedProductsStore, (lists) => {
    const newList = lists.map((item) => {
      const sortedProducts = { added: [], rest: [] };
      item.products.forEach((product) => {
        if (product.quantity > 0) {
          sortedProducts.added.push(product);
        } else {
          sortedProducts.rest.push(product);
        }
      });
      return {
        ...item,
        products: [...sortedProducts.added, ...sortedProducts.rest],
      };
    });
    return newList;
  });
};

export const changeQuantity = ({
  productId,
  quantity,
}: {
  productId: number;
  quantity: number;
}) => {
  update(relatedProductsStore, (lists) => {
    const newLists = lists.map((list) => {
      const newProducts = list.products.map((product) => {
        if (product.id !== productId) return product;

        return { ...product, quantity };
      });

      return {
        ...list,
        products: newProducts,
      };
    });

    return newLists;
  });
};

export const useProducts = (params: Params): ProductData[] => {
  const { listId } = params;
  const items = useStore(relatedProductsStore);
  let products = [];
  items.forEach((list) => (products = [...products, ...list.products]));

  if (listId === 'warranty') {
    return products.filter((product) => product.inAdditionalWarranty);
  }

  return items.find((list) => list.id === listId)?.products || [];
};

// метод для установки значения стора для тестов
export const setStoreValue = (initialValue: ListData[]) => {
  relatedProductsStore.set(initialValue);
};

export const useRelatedProducts = () => {
  return useStore(relatedProductsStore);
};

export const useSelected = () => {
  return useStore(selectedStore);
};

keepActive(relatedProductsStore);
