import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface Params {
  cartPositionId: string;
}

async function removeHidden() {
  const url = '/backend/cart-2/remove-hidden';
  const res = await Api.queryProxi<any>(url);

  if (res.result !== 'success') throw new BackendError(url, res);

  return res.data;
}

export default removeHidden;
