import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

export interface Params {
  cartPositionId: string;
}

async function remove(params: Params | Params[]) {
  const url = '/backend/cart-2/remove';
  const res = await Api.queryProxi<any>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (res.result !== 'success') throw new BackendError(url, res);

  return res.data;
}

export default remove;
