import { useQuery } from '@tanstack/react-query';

import ApiSite from '@Api/Site';

import type { DeviceDetectorResult } from 'device-detector-js';
import type { UseQueryResult } from '@tanstack/react-query';
import type { DeviceResult } from 'device-detector-js/dist/parsers/device';
import type { ClientResult } from 'device-detector-js/dist/parsers/client';

// Дефолтные значения для устройств, которые не удалось определить,
// необходимы для предотвращения ошибок, где используются эти данныне
const defaultClient: ClientResult = {
  type: 'browser',
  name: 'Chrome',
  version: '117.0',
  engine: 'Blink',
  engineVersion: '',
};

const defaultDevice: DeviceResult = {
  type: 'desktop',
  brand: '',
  model: '',
};

const useDevice = (): UseQueryResult<DeviceDetectorResult> => {
  const keys = ['device'];
  const result = useQuery({
    queryKey: keys,
    queryFn: ApiSite.detectDevice,
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  if (!result.data.client) result.data.client = defaultClient;
  if (!result.data.device) result.data.device = defaultDevice;

  return result;
};

export default useDevice;
