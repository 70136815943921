import { useEffect } from 'react';

import useDeps from '@Contexts/DI/useDeps';
import useNavigation from '@Navigation/useNavigation';

const Page = ({ children }) => {
  const { pathname } = useNavigation();
  const { eventManager } = useDeps();

  useEffect(() => {
    eventManager.dispatch('page.change', { pathname });
  }, [eventManager, pathname]);

  return children;
};

export default Page;
