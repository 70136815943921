import useRequest from './useRequest';

const useCountries = () => {
  const { country } = useRequest();

  const isRus = country === 'RUS';
  const isBlr = country === 'BLR';
  const isKaz = country === 'KAZ';
  const isUzb = country === 'UZB';

  return { isRus, isBlr, isKaz, isUzb };
};

export default useCountries;
