import useRequest from '@Hooks/useRequest';
import iconUz from './icons/uz.svg';
import iconRu from './icons/ru.svg';
import iconEn from './icons/en.svg';
import iconKK from './icons/kk.svg';

const names = {
  ru: 'Rus',
  uz: `О'zbek`,
  en: 'English',
  kk: 'Казақша',
};

const icons = {
  ru: iconRu,
  uz: iconUz,
  en: iconEn,
  kk: iconKK,
};

export function useLanguages() {
  const { language, supportedLanguages } = useRequest();

  return {
    language: {
      id: language.id,
      code: language.id.toUpperCase(),
      name: names[language.id],
      icon: icons[language.id],
    },
    supportedLanguages: supportedLanguages.map((supportedLanguage) => ({
      id: supportedLanguage,
      code: supportedLanguage.toUpperCase(),
      name: names[supportedLanguage],
      icon: icons[supportedLanguage],
    })),
  };
}
