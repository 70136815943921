import { useMemo } from 'react';

import useRequest from '@Hooks/useRequest';

import type { CountryBasedData } from '@Types/Base';

type Default<T> = {
  default?: T;
};

export type CountryBasedConfig<T> = CountryBasedData<T> & Default<T>;

/**
 * Вычисляет значение на основе текущей страны пользователя.
 * @param config Объект со значениями для одной или нескольких стран. В поле default можно передать значения для всех остальных стран.
 * @returns Возвращает значение, которое соответствует текущей стране, либо значение по ключу default, либо null
 */
function useCountryBasedData<T>(config: CountryBasedConfig<T>): T {
  const { country } = useRequest();

  const result = useMemo(() => {
    return config[country] || config.default || null;
  }, [config, country]);

  return result;
}

export default useCountryBasedData;
