import { EventListener } from '@Events/EventListener';

import type { CartData } from '@Types/Cart';
import type { ResultData } from '@Api/Compare/getCompareProducts';
import type AnalyticsEventDispatcher from '@Infrastructure/details/analytics/AnalyticsEventDispatcher';

export class AnalyticsCartLoadListener extends EventListener {
  private cart: CartData;

  private compareProducts: ResultData;

  private wasEventDispatched: boolean;

  private analytics: AnalyticsEventDispatcher;

  constructor(deps: { analytics: AnalyticsEventDispatcher }) {
    super();
    this.cart = null;
    this.compareProducts = null;
    this.wasEventDispatched = false;
    this.analytics = deps.analytics;
    this.on('cart.load', this.cartLoad.bind(this));
    this.on('compareProducts.load', this.compareProductsLoad.bind(this));
  }

  cartLoad(cart: CartData) {
    this.cart = cart;
    this.dispatchCartInit();
  }

  compareProductsLoad(compareProducts: ResultData) {
    this.compareProducts = compareProducts;
    this.dispatchCartInit();
  }

  dispatchCartInit() {
    if (this.wasEventDispatched || !this.cart || !this.compareProducts) return;

    this.analytics.dispatchEvent('cart.init', { cart: this.cart, favorite: this.compareProducts });

    this.wasEventDispatched = true;
  }
}
