import { useInfiniteQuery } from '@tanstack/react-query';

import * as Api from '@Api/index';
import * as ApiCategory from '@Api/Category';
import { filterSearchParams } from '@Queries/helpers/generateQueryKeys';
import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';

import type { InfiniteData, QueryClient, UseInfiniteQueryResult } from '@tanstack/react-query';
import type { CatalogData } from '@Types/Catalog';

export interface Params {
  slug: string;
}

interface PrefetchCategoryParams {
  search: string;
  slug: string;
  pageNumber: number;
}

export type Result = UseInfiniteQueryResult<InfiniteData<CatalogData>>;

function getKeys(input: { slug: string; typeOfLoading: string; search: string; page: number }) {
  const { region } = Api.getRequest();
  const { slug, search, page, typeOfLoading } = input;
  const filteredSearch = filterSearchParams(search);

  const keys = [
    'infiniteCategory',
    { slug, typeOfLoading, page: typeOfLoading === 'auto' ? undefined : page },
    { region },
    filteredSearch,
  ].filter(Boolean);

  return keys;
}

function getCachedData(input: {
  slug: string;
  queryClient: QueryClient;
  search: string;
  page: number;
}): {
  typeOfLoading: string;
  data: CatalogData | null;
} {
  const { slug, queryClient, page, search } = input;
  // AUTO
  const keysAuto = getKeys({ slug, typeOfLoading: 'auto', page, search });
  const cacheAuto = queryClient.getQueryData<Record<string, CatalogData[]>>(keysAuto);

  if (cacheAuto) return { typeOfLoading: 'auto', data: cacheAuto.pages[0] };

  // PAGINATOR
  const keysPaginator = getKeys({ slug, typeOfLoading: 'paginator', page, search });
  const cachePaginator = queryClient.getQueryData<Record<string, CatalogData[]>>(keysPaginator);

  if (cachePaginator) return { typeOfLoading: 'paginator', data: cachePaginator.pages[0] };

  if (!cacheAuto && !cachePaginator)
    return { typeOfLoading: page > 1 ? 'paginator' : 'auto', data: null };
}

// Информация о категории товаров
export const prefetchInfiniteCategory = (
  params: PrefetchCategoryParams,
  queryClient: QueryClient,
) => {
  const { search, slug, pageNumber } = params;
  const { data, typeOfLoading } = getCachedData({ slug, queryClient, search, page: pageNumber });
  const keys = getKeys({
    slug,
    typeOfLoading,
    search,
    page: pageNumber,
  });

  if (data) return Promise.resolve(data);

  return queryClient.prefetchInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      return ApiCategory.getProducts({
        slug,
        page: pageNumber || pageParam,
        search,
        isInit: true,
      });
    },
    initialPageParam: 0,
  });
};

export const useInfiniteCategory = (params: Params): Result => {
  const { slug } = params || {};
  const { queryClient } = useRequest();
  const { pathname, search } = useNavigation();
  const matches = pathname.match(/.*page-([0-9]{0,})$/);
  const page = Number(matches ? matches[1] : 1);

  const { typeOfLoading, data } = getCachedData({ slug, queryClient, search, page });
  const keys = getKeys({ slug, typeOfLoading, search, page });

  const category = useInfiniteQuery({
    queryKey: keys,
    queryFn: ({ pageParam }) => {
      if (data && !pageParam) return Promise.resolve(data);

      return ApiCategory.getProducts({
        slug,
        page: pageParam || page,
        search,
        isInit: !pageParam,
      });
    },
    initialPageParam: 0,
    enabled: true,
    placeholderData: (previousData) => previousData,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return null;

      const nextPageParam = lastPage.page + 1;

      return nextPageParam > lastPage.pageCount ? null : nextPageParam;
    },
  });

  return category;
};
