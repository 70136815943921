import { lazy, memo, useMemo, useEffect } from 'react';

import * as CookiesPolicy from '@Stores/CookiesPolicy';
import * as Meta from '@Queries/Meta';
import { useLayout } from '@Queries/useLayout';
import useMedias from '@Hooks/useMedias';
import Suspense from '@Components/Suspense';
import useRequest from '@Hooks/useRequest';
import SideBar from '@Layouts/elems/SideBar';
import Header from './elems/Header';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import ErrorInfo from '@Components/ErrorInfo';
import ErrorBoundary from '@Components/ErrorBoundary';
import ModalWindowsManager from '@Components/ModalWindowsManager';
import DeliveryInfo from './elems/DeliveryInfo';
import Footer from './elems/Footer';

import type { FC, HTMLAttributes, ReactNode } from 'react';

const BottomPanel = lazy(() => import('@Layouts/elems/BottomPanel'));
const CookiesAgreement = lazy(() => import('@Layouts/LayoutMain/elems/CookiesAgreement'));

export interface LayoutMainProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  unfixHeader?: boolean;
}

const LazyElems: FC = () => {
  const { isOnlyMobile } = useMedias();
  const { country } = useRequest();
  const agreement = CookiesPolicy.useCookiesPolicy();

  useEffect(() => {
    CookiesPolicy.init(country);
  }, [country]);

  return (
    <Suspense fallback={null}>
      <BottomPanel />

      {isOnlyMobile && <SideBar />}

      {agreement.available && agreement.accepted === null && <CookiesAgreement />}
    </Suspense>
  );
};

const LayoutMain: FC<LayoutMainProps> = ({ children }) => {
  const { isDeliveryPage } = useRouteMatchers();
  const { country } = useRequest();
  const layout = useLayout();
  const meta = Meta.useMeta();

  // Нужно ли показывать блок с доставкой?
  const needDeliveryInfo = useMemo(() => {
    if (isDeliveryPage) return false;
    if (!['RUS', 'KAZ'].includes(country)) return false;
    return true;
  }, [isDeliveryPage, country]);

  return (
    <ErrorBoundary fallback={<ErrorInfo />}>
      <>
        <Header />

        {children}

        <ModalWindowsManager />

        {needDeliveryInfo && <DeliveryInfo country={country} phones={meta.data.phones} />}
        <Footer footer={layout.data.footer} />
        <LazyElems />
      </>
    </ErrorBoundary>
  );
};

export default memo(LayoutMain);
