import { type DeviceDetectorResult } from 'device-detector-js';

import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

async function detectDevice() {
  const { headers } = Api.getRequest();
  const userAgent = headers['user-agent'] || '';
  const search = new URLSearchParams({ userAgent }).toString();
  const qs = search ? `?${search}` : '';
  const url = `/detect-device${qs}`;
  const res = await Api.query<ApiResponse<DeviceDetectorResult>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default detectDevice;
