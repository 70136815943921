import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import OrderFormStore from '@Stores/OrderForm/OrderFormStore';
import transformCart from '@Queries/Cart/helpers/transformCart';
import useRequest from '@Hooks/useRequest';
import useCartKeys from './useCartKeys';

import type { CartData, CartModalInputProduct } from '@Types/Cart';

export interface Variables {
  products: CartModalInputProduct[];
  options: { isRelated: boolean };
  analyticsLabel?: string;
  oldHash?: string;
}

export const useUpdatePositionInCart = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  return useMutation<CartData, Error, Variables>({
    mutationFn: async ({ products, options, oldHash }) => {
      const res = await ApiCart.updatePosition({ products, oldHash });

      return transformCart({ cart: res, options });
    },
    onSuccess: (cart, { products }) => {
      const paymentTypeId = products.find((product) => product.paymentTypeId)?.paymentTypeId;
      if (paymentTypeId) {
        OrderFormStore.select({ paymentType: paymentTypeId });
      }

      queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
    },
  });
};

export default useUpdatePositionInCart;
