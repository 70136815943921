import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { CartData } from '@Types/Cart';

export interface Result {
  result: 'success' | 'error';
  data: CartData;
}

async function sendPromocode(params: CartData | unknown) {
  if (!params) return;

  const url = '/backend/order/coupon';

  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default sendPromocode;
