import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import ApiSite from '@Api/Site';
import useSearchParams from '@Hooks/useSearchParams';
import generateQueryKeys from '@Queries/helpers/generateQueryKeys';
import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';
import { useDevPanel } from '@Stores/DevPanel';
import useGlobalConfig from '@Hooks/useGlobalConfig';

import type { UseQueryResult } from '@tanstack/react-query';
import type { BasePage } from '@Types/Base';

let placeholderData: BasePage = null;

function usePage<T>(queryKeys: string[] = []): UseQueryResult<T & BasePage> {
  const { queryClient, region } = useRequest();
  const location = useNavigation();

  const { currentDate } = useDevPanel();
  const { isProduction } = useGlobalConfig();
  const searchParams = useSearchParams();

  if (!isProduction) {
    searchParams.set('currentDate', (+currentDate).toString());
  }

  const url = `${location.pathname}?${searchParams}`;
  const keys = generateQueryKeys({
    keys: ['page', region, ...queryKeys],
    pathname: location.pathname,
    search: location.search,
  });

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData<T & BasePage>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiSite.fetchPage<T & BasePage>({ url });
    },
    enabled: true,
    placeholderData: () => placeholderData as any,
  });

  useEffect(() => {
    if (query?.data && query?.data.page !== placeholderData?.page) {
      placeholderData = query.data;
    }
  }, [query?.data]);

  useEffect(() => {
    if (!placeholderData) {
      placeholderData = queryClient.getQueryData(keys);
    }
    // Заполняем placeholderData только при первом рендере
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return query;
}

export default usePage;
