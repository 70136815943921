import { memo } from 'react';
import { PopoverInfo } from '@divlab/divanui';
import { IconInfoPopover } from '@divlab/divanui/icons';

import * as Experiments from '@Queries/Experiments';
import SubscriptionForm from '@Forms/SubscriptionForm';
import SocialList from '@Components/SocialList';
import SmartRender from '@Components/SmartRender';
import FooterNav from '@Layouts/LayoutMain/elems/Footer/common/FooterNav';
import FooterInfoBlock from '@Layouts/LayoutMain/elems/Footer/common/FooterInfoBlock';
import Accordion from '@Layouts/LayoutMain/elems/Footer/common/Accordion';
import useTranslation from '@Queries/useTranslation';
import PaymentSystems from '../PaymentSystems';
import FooterLogo from '../FooterLogo';
import DeveloperLink from '../DeveloperLink';
import FooterOfferDoc from '../FooterOfferDoc';
import FooterPublicOffers from '../FooterPublicOffers';
import styles from './FooterMobile.module.css';

import type { FC } from 'react';
import type { FooterProps } from '../../FooterRus';

const FooterMobile: FC<FooterProps> = (props) => {
  const { footer } = props;
  const featureFlags = Experiments.useFeatureFlags();
  const { t } = useTranslation();

  return (
    <div className={styles.grid}>
      {footer.contacts?.items.length > 0 && (
        <div className={styles.col}>
          <Accordion header={footer.contacts.title}>
            <FooterNav items={footer.contacts.items} />
          </Accordion>
        </div>
      )}

      <div className={styles.col}>
        <div className={styles.wrapperBig}>
          {footer.callSchedule?.items.length > 0 && (
            <div className={styles.wrapperMedium}>
              <FooterInfoBlock title={footer.callSchedule.title}>
                <FooterNav items={footer.callSchedule.items} />
              </FooterInfoBlock>
            </div>
          )}

          {footer.deliverySchedule?.items.length > 0 && (
            <div>
              <FooterInfoBlock title={footer.deliverySchedule.title}>
                <FooterNav items={footer.deliverySchedule.items} />
              </FooterInfoBlock>
            </div>
          )}
        </div>
        {footer.subscribe && (
          <FooterInfoBlock title={footer.subscribe.title}>
            <div className={styles.footerSubscription}>
              <SubscriptionForm source='footer' />
            </div>
          </FooterInfoBlock>
        )}

        {footer.press?.items.length > 0 && (
          <FooterInfoBlock title={footer.press.title}>
            <FooterNav items={footer.press.items} />
          </FooterInfoBlock>
        )}
      </div>

      {footer.toCustomers?.items.length > 0 && (
        <div className={styles.col}>
          <Accordion header={footer.toCustomers.title} defaultCollapsed>
            <FooterNav items={footer.toCustomers.items} />
          </Accordion>
        </div>
      )}

      <div className={styles.col}>
        {footer.catalog?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <Accordion header={footer.catalog.title} defaultCollapsed>
              <FooterNav items={footer.catalog.items} />
            </Accordion>
          </div>
        )}
        {footer.services?.items.length > 0 && (
          <FooterInfoBlock title={footer.services.title}>
            <FooterNav items={footer.services.items} />
          </FooterInfoBlock>
        )}
      </div>

      <div className={styles.col}>
        {footer.payments?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.payments.title}>
              <PaymentSystems items={footer.payments.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.reviews?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <Accordion header={footer.reviews.title}>
              <FooterNav items={footer.reviews.items} />
            </Accordion>
          </div>
        )}

        {footer.awards?.items.length > 0 && (
          <FooterInfoBlock title={footer.awards.title}>
            <FooterNav items={footer.awards.items} />
          </FooterInfoBlock>
        )}
      </div>

      <div className={styles.col}>
        <div className={styles.wrapperBig}>
          <FooterLogo />
        </div>

        {footer.socials?.items.length > 0 && (
          <SocialList className={styles.wrapperBig} items={footer.socials.items} />
        )}

        {footer.offerDocs?.map((item) => (
          <div key={item.text} className={styles.linkWrapper}>
            <FooterOfferDoc document={item} />
            <SmartRender countries={['UZB']}>
              <PopoverInfo
                preferredPositions={['top-center']}
                slotTrigger={<IconInfoPopover />}
                className={styles.popoverInfo}
              >
                <div className={styles.popover}>{t('ui.footer.oferta-popover')}</div>
              </PopoverInfo>
            </SmartRender>
          </div>
        ))}

        <div className={styles.linkWrapper}>
          <FooterOfferDoc
            document={{
              action: 'StaticInfo',
              slug: 'privacy-policy',
              text: t('ui.footer-links.privacy-policy'),
            }}
          />
        </div>

        {footer.publicOffers?.items && (
          <div className={styles.linkWrapper}>
            <FooterPublicOffers publicOffers={footer.publicOffers} />
          </div>
        )}

        {footer?.paymentSecurity && (
          <div className={styles.linkWrapper}>
            <FooterOfferDoc
              document={{
                action: footer.paymentSecurity.action,
                slug: footer.paymentSecurity.slug,
                text: footer.paymentSecurity.title,
              }}
            />
          </div>
        )}

        {featureFlags.needLinkToPaymentSecurity && (
          <div className={styles.linkWrapper}>
            <FooterOfferDoc
              document={{
                action: 'StaticInfo',
                slug: 'payment-security',
                text: t('ui.footer-links.payment-security'),
              }}
            />
          </div>
        )}

        {featureFlags.needLinkToDeveloper && (
          <div className={styles.linkWrapper}>
            <DeveloperLink />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FooterMobile);
