import { useMemo } from 'react';

import useRequest from '@Hooks/useRequest';
import BLRFooter from './countries/BLR';
import RUSFooter from './countries/RUS';

import type { FC } from 'react';
import type { FooterData } from '@Types/Layout';

interface FooterProps {
  footer: FooterData;
}

const Footer: FC<FooterProps> = (props) => {
  const { footer } = props;
  const { country } = useRequest();

  const countryFooter = useMemo(() => {
    // Для Казахстана и Узбекистана пока что не нужна отдельная версия и подойдёт российская
    if (['KAZ', 'UZB'].includes(country)) return 'RUS';

    return country;
  }, [country]);

  switch (countryFooter) {
    case 'BLR':
      return <BLRFooter footer={footer} />;
    case 'RUS':
      return <RUSFooter footer={footer} />;
    default:
      return <RUSFooter footer={footer} />;
  }
};

export default Footer;
