import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData, RelatedProductsData } from '@Types/Cart';

export interface Variables {
  productIds: number[];
}

function useLoadRelatedProducts() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();

  return useMutation<RelatedProductsData, Error, Variables>({
    mutationFn: ({ productIds }) => {
      return ApiCart.loadRelatedProducts({ productIds });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(keys, (prev: CartData) => {
        return { ...prev, relatedProducts: data.relatedProducts, relatedTitle: data.title };
      });
    },
  });
}

export default useLoadRelatedProducts;
