import { useQuery } from '@tanstack/react-query';

import useDeps from '@Contexts/DI/useDeps';

export const useDesignerRegions = () => {
  const { cabinetApi } = useDeps();
  const keys = ['designerRegions'];

  const query = useQuery({ queryKey: keys, queryFn: cabinetApi.fetchDesignerRegions });

  return query;
};
