import hide from './hide';
import info from './info';
import loadRelatedProducts from './loadRelatedProducts';
import put from './put';
import remove from './remove';
import unhide from './unhide';
import update from './update';
import sendDerelictCart from './sendDerelictCart';
import removeHidden from './removeHidden';
import sendPromocode from './sendPromocode';
import sendCheckerPromocode from './sendCheckerPromocode';
import sendBonus from './sendBonus';
import updatePosition from './updatePosition';

export default {
  hide,
  info,
  loadRelatedProducts,
  put,
  remove,
  unhide,
  update,
  sendBonus,
  sendDerelictCart,
  removeHidden,
  sendPromocode,
  updatePosition,
  sendCheckerPromocode,
};
