import { useCallback } from 'react';
import { Price } from '@divlab/divanui';

import * as SearchStore from '@Stores/Search';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import getProductName from '@Utils/getProductName';
import useDeps from '@Contexts/DI/useDeps';
import CardInView from '@Components/CardInView';
import styles from './ProductList.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface ProductListProps extends HTMLAttributes<HTMLDivElement> {
  products: ProductData[];
  onClickLink?: (e: MouseEvent) => void;
}

const ProductList: FC<ProductListProps> = (props) => {
  const { products, onClickLink } = props;
  const { result } = SearchStore.useSearch();
  const { analytics } = useDeps();

  const handleClick = useCallback(
    (e: MouseEvent, product: ProductData) => {
      analytics.dispatchEvent('search.product.click', {
        searchResult: result,
        product,
      });

      if (onClickLink) onClickLink(e);
    },
    [onClickLink, result, analytics],
  );

  return (
    <div className={styles.list}>
      {products.length > 0 && (
        <div className={styles.row}>
          {products.map((item, index) => (
            <Link
              key={item.id}
              className={styles.link}
              to={item.link}
              onClick={(e) => handleClick(e, item)}
            >
              <CardInView
                id={item.id}
                listTitle='Поиск'
                listType='search'
                position={index}
                card={item}
                cardType='product'
              >
                <Img
                  className={styles.imgWrapper}
                  src={item.images[0]?.src}
                  cnImage={styles.cnImage}
                />
                <div className={styles.name}>{getProductName(item)}</div>
                <Price className={styles.price} price={item.price.actual} />
              </CardInView>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
