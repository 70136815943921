import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useFeatureFlags from '@Hooks/useFeatureFlags';
import Item from './Item/Item';
import ItemWithPopup from './ItemWithPopup';
import LanguageSwitch from '../../../LanguageSwitch';
import styles from './MenuRight.module.css';

import type { MainMenuItem } from '@Types/Layout';
import type { FC, HTMLAttributes } from 'react';

export interface MenuRightProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: MainMenuItem[];
}

const MenuRight: FC<MenuRightProps> = (props) => {
  const { className, items, ...oldProps } = props;
  const { multipleLangAvailable } = useFeatureFlags();

  return (
    <div {...oldProps} className={cn(styles.mainWrapper, className)}>
      {multipleLangAvailable && <LanguageSwitch />}

      {items.map((menuItem, index) => {
        const isLast = index === items.length - 1;

        return menuItem.children?.length > 0 ? (
          <div className={styles.menuItem} key={menuItem.id}>
            <ItemWithPopup item={menuItem} positionPopup={isLast ? 'right' : 'left'} />
          </div>
        ) : (
          <div className={styles.menuItem} key={menuItem.id}>
            <Item item={menuItem} />
          </div>
        );
      })}
    </div>
  );
};

export default memo(MenuRight);
