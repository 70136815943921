import { memo, useMemo } from 'react';
import { cn, useMedias } from '@divlab/divanui';

import Sticker from '@Promo/elems/Sticker';
import Link from '@Navigation/Link';
import Img from '@UI/Img';
import UILink from '@UI/Link';
import Header from '@Promo/elems/Header';
import Subtitle from '@Promo/elems/Subtitle';
import styles from './SaleBanner.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { MenuSaleBanner } from '@Promo/typings';

export interface BannerGalleryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  banner: MenuSaleBanner;
  view: 'fullscreen' | 'halfscreen';
  onClickLink?: (e: MouseEvent) => void;
}

const SaleBanner: FC<BannerGalleryProps> = (props) => {
  const { className, banner, view = 'halfscreen', onClickLink, ...restProps } = props;
  const { isDesktop } = useMedias();

  const bannerImage = useMemo(() => {
    if (isDesktop) return banner.bgMobileImage;

    return banner.bgFullImage && view === 'fullscreen' ? banner.bgFullImage : banner.bgImage;
  }, [isDesktop, banner, view]);

  return (
    <div
      {...restProps}
      className={cn(
        styles.banner,
        {
          [styles.fullscreen]: view === 'fullscreen',
          [styles.halfscreen]: view === 'halfscreen',
          [styles.themeLight]: banner.theme === 'light',
          [styles.themeDark]: banner.theme === 'dark',
          [styles.aligmentLeft]: banner.alignment === 'left',
        },
        className,
      )}
    >
      <Link className={styles.link} to={banner.link} onClick={onClickLink}>
        <div className={styles.imageContainer} style={{ backgroundColor: banner.bgColor }}>
          <Img className={styles.image} src={bannerImage} />
          {!!banner.sticker && (
            <Sticker className={styles.sticker} type={banner.sticker.type}>
              {banner.sticker.text}
            </Sticker>
          )}
        </div>
        <div className={styles.titleContainer}>
          {banner.title && <Header className={styles.title} text={banner.title} />}
          {banner.description && (
            <Subtitle className={styles.description} text={banner.description} />
          )}
          {!!banner.buttonText && (
            <UILink
              view={banner.theme === 'dark' ? 'white' : 'primary'}
              animated
              className={styles.moreLink}
            >
              {banner.buttonText}
            </UILink>
          )}
        </div>
      </Link>
    </div>
  );
};

export default memo(SaleBanner);
