import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';
import useRequest from '@Hooks/useRequest';

import type { ProductData } from '@Types/Product';

function useInCompare(id: ProductData['id'], hash?: string) {
  const { region, language } = useRequest();
  return useQuery({
    queryKey: ['compare', region, language],
    queryFn: ApiCompare.getCompareProducts,
    notifyOnChangeProps: ['data'],
    placeholderData: { products: [] },
    select: ({ products }) =>
      products.some((product) => {
        if (product?.hash) return product.hash === hash && product.id === id;

        return product.id === id;
      }),
  });
}

export default useInCompare;
