import { useMutation } from '@tanstack/react-query';

import * as Cart from '@Queries/Cart';
import ApiCart from '@Api/Cart';
import useCartKeys from './useCartKeys';
import useRequest from '@Hooks/useRequest';

import type { CartData } from '@Types/Cart';

export const useApplyBonus = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const cart = Cart.useCart();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ signal }: AbortController) => {
      const res = await ApiCart.sendBonus(
        {
          'BonusPointsForm[value]': cart.data.bonusPoints?.availableAmount,
        },
        { signal },
      );
      return res;
    },
    onSuccess: (NewCart) => {
      queryClient.setQueriesData({ queryKey: keys }, (prev: CartData) => {
        return {
          ...prev,
          ...NewCart,
        };
      });
    },
  });

  return {
    applyBonus: mutate,
    isPending,
  };
};

export default useApplyBonus;
