import { useEffect } from 'react';

import LayoutMain from '@Layouts/LayoutMain';
import HttpStatus from '@Navigation/HttpStatus';
import Link from '@Navigation/Link';
import useTranslation from '@Queries/useTranslation';
import useDeps from '@Contexts/DI/useDeps';
import Search from './elements/Search';
import styles from './Page404.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Page404: FC<Props> = () => {
  const { t } = useTranslation();
  const { analytics } = useDeps();

  useEffect(() => {
    setTimeout(() => {
      analytics.dispatchEvent('page.error404');
    });
  }, [analytics]);

  return (
    <LayoutMain>
      <HttpStatus code={404}>
        <div className={styles.page} data-testid='page-404'>
          <div className={styles.wrapperIcon}>
            <div className={styles.iconCode} />
          </div>
          <div className={styles.contentWrapper}>
            <h1 className={styles.heading}>{t('ui.404.title')}</h1>
            <div className={styles.text}>{t('ui.404.description')}</div>
            <Search />
            <Link underlined className={styles.link} to='/' view='primary'>
              {t('ui.404.to-main')}
            </Link>
          </div>
        </div>
      </HttpStatus>
    </LayoutMain>
  );
};

export default Page404;
