import { memo, useEffect } from 'react';

import * as HeaderStore from '@Stores/Header';
import * as CookiesPolicy from '@Stores/CookiesPolicy';
import { useLayout } from '@Queries/useLayout';
import useRequest from '@Hooks/useRequest';
import ErrorInfo from '@Components/ErrorInfo';
import ErrorBoundary from '@Components/ErrorBoundary';
import useNavigation from '@Navigation/useNavigation';

import type { FC, HTMLAttributes, ReactNode } from 'react';

export interface PageWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
  const { pathname } = useNavigation();
  const layout = useLayout();
  const { country } = useRequest();

  HeaderStore.init({
    items: layout.data?.header,
    pathname,
  });

  useEffect(() => {
    CookiesPolicy.init(country);
  }, [country]);

  return <ErrorBoundary fallback={<ErrorInfo />}>{children}</ErrorBoundary>;
};

export default memo(PageWrapper);
