import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import * as ApiMeta from '@Api/Meta';
import useRequest from '@Hooks/useRequest';

import type { MetaData } from '@Types/Meta';
import type { UseQueryResult } from '@tanstack/react-query';

let placeholderData: MetaData = null;

function useMeta(): UseQueryResult<MetaData> {
  const { region, secondaryRegion, language, targetRegion, targetSecondaryRegion, queryClient } =
    useRequest();

  const keys = [
    'meta',
    {
      region: targetRegion || region,
      secondaryRegion: targetSecondaryRegion || secondaryRegion,
      lang: language.id,
    },
  ];

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      return ApiMeta.fetch();
    },
    enabled: true,
    placeholderData: () => placeholderData,
  });

  useEffect(() => {
    if (query?.data.region.id !== placeholderData?.region?.id) {
      placeholderData = query.data;
    }
  }, [query?.data]);

  useEffect(() => {
    if (!placeholderData) {
      placeholderData = queryClient.getQueryData(keys);
    }
    // Заполняем placeholderData только при первом рендере
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return query;
}

export default useMeta;
