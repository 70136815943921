import { useEffect } from 'react';
import { useStore } from '@kundinos/nanostores/react';
import { update, createStore } from '@kundinos/nanostores';

import type { ReactElement } from 'react';

export interface Components {
  buyButton?: ReactElement;
  orderTextile?: ReactElement;
  orderButton?: ReactElement;
  filters?: ReactElement;
}

const сomponentStorage = createStore<Components>(() => {
  сomponentStorage.set({});
});

export const useComponentStorage = (): Readonly<Components> => {
  return useStore(сomponentStorage);
};

export const updateComponentStorage = (components: Partial<Components>) => {
  update(сomponentStorage, (prev) => {
    return { ...prev, ...components };
  });
};

export function useUpdateComponentStorage(components: Partial<Components>) {
  if (!!components) {
    update(сomponentStorage, (prev) => {
      return { ...prev, ...components };
    });
  }

  useEffect(() => {
    if (!components) return;

    return () => {
      const diff = {};
      Object.keys(components).forEach((key) => (diff[key] = null));

      update(сomponentStorage, (prev) => {
        return { ...prev, ...diff };
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default сomponentStorage;
