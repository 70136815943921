import { cn } from '@divlab/divanui';

import * as Meta from '@Queries/Meta';
import Phone from '@Components/Phone';
import styles from './FeedbackPhone.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface FeedbackPhoneProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  view?: 'primary' | 'secondary';
  size?: 's' | 'm';
}

const FeedbackPhone: FC<FeedbackPhoneProps> = (props) => {
  const { className, view = 'secondary', size = 's', ...restProps } = props;
  const meta = Meta.useMeta();

  if (!meta.isSuccess) return null;

  return (
    <div
      {...restProps}
      className={cn(
        styles.phoneWrapper,
        {
          [styles.viewSecondary]: view === 'secondary',
          [styles.viewPrimary]: view === 'primary',
          [styles.sizeS]: size === 's',
          [styles.sizeM]: size === 'm',
        },
        className,
      )}
    >
      <Phone className={styles.phone} phone={meta.data.phones[0]} />
    </div>
  );
};

export default FeedbackPhone;
