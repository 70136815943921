import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

import useGlobalConfig from '@Hooks/useGlobalConfig';

import type { FC } from 'react';

const FontCloserText: FC = () => {
  const { сdnUrl } = useGlobalConfig();

  return (
    <Helmet>
      <link rel='stylesheet' href={`${сdnUrl}/public/fonts/closer-text/font.v1.css`} />
    </Helmet>
  );
};

export default memo(FontCloserText);
