import { memo, useCallback, useEffect, useRef } from 'react';
import { cn } from '@divlab/divanui';
import { IconSearch } from '@divlab/divanui/icons';

import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import * as ModalWindows from '@Stores/ModalWindows';
import useDeps from '@Contexts/DI/useDeps';
import useCountryBasedData from '@Hooks/useCountryBasedData';
import Logotype from '@Components/Logotype';
import LogotypeDivanClub from '@Components/LogotypeDivanClub';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import useRequest from '@Hooks/useRequest';
import UserMenu from '../UserMenu';
import Burger from '../Burger';
import styles from './HeaderMobile.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CountryBasedData } from '@Types/Base';

export interface HeaderMobileProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  withShadow?: boolean;
}

const siteNameData: CountryBasedData<string> = {
  RUS: 'divan.ru',
  BLR: 'divan.by',
  KAZ: 'mebel.kz',
  UZB: 'divan.uz',
};

const HeaderMobile: FC<HeaderMobileProps> = (props) => {
  const { className, withShadow, ...restProps } = props;
  const { logger } = useDeps();
  const { isDivanClub } = useRouteMatchers();
  const headerRef = useRef<HTMLDivElement>(null);
  const siteName = useCountryBasedData(siteNameData);
  const { country } = useRequest();
  const search = SearchStore.useSearch();
  const defaultValue = search.result.request;

  const handleClick = useCallback(async () => {
    try {
      await SearchStore.search({ term: defaultValue || '' });

      ModalWindows.open('Search');
    } catch (err) {
      logger.log(err);
      HeaderStore.closeSearch();
    }
  }, [defaultValue, logger]);

  // Рассчитываем высоту шапки
  useEffect(() => {
    function calculate() {
      setTimeout(() => {
        if (!headerRef.current) return;
        const rectHeader = headerRef.current.getBoundingClientRect();
        HeaderStore.setSizes({ contentHeight: rectHeader.height });
      });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, []);

  return (
    <div
      {...restProps}
      ref={headerRef}
      className={cn(
        styles.header,
        {
          [styles.withShadow]: withShadow,
        },
        className,
      )}
    >
      <div className={styles.left}>
        <Burger onClick={HeaderStore.openSideBar} data-testid='catalog-top' />
        <IconSearch className={styles.iconSearch} onClick={handleClick} data-testid='search' />
      </div>

      <Link to='/' aria-label={siteName} data-testid='logo-link'>
        {isDivanClub ? <LogotypeDivanClub /> : <Logotype country={country} />}
      </Link>

      <UserMenu className={styles.userMenu} />
    </div>
  );
};

export default memo(HeaderMobile);
