import { cn, useMedias } from '@divlab/divanui';

import { useDevPanel } from '@Stores/DevPanel';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import Text from '@Promo/elems/Text';
import Timer from '@Promo/elems/Timer';
import CardInView from '@Components/CardInView';
import styles from './PromotionTemplate.module.css';

import type { FC } from 'react';
import type { PromotionTemplateProps } from '../../typings';
import type { PromotionsBannerData } from '@Promo/typings';

const PromotionTemplate: FC<PromotionTemplateProps> = (props) => {
  const { cnTemplate, promotion, size, index, onClickLink, ...restProps } = props;
  const { isDesktop } = useMedias();
  const { currentDate } = useDevPanel();
  const banner = promotion.materials[0];
  const text = banner.text || ({} as PromotionsBannerData['text']);

  const resources = banner.resources.default;
  let image = size === 'full' ? resources.fullDesktopImage : resources.halfDesktopImage;
  if (isDesktop) image = resources.mobileImage;

  const needTimer = (isDesktop || size === 'full') && currentDate > new Date(banner.timerStartDate);

  const alignmentX = size === 'half' ? 'left' : banner.alignmentX || 'left';
  const alignmentY = needTimer || size === 'half' ? 'top' : banner.alignmentY || 'center';

  const inversed =
    isDesktop && typeof banner.inversedMobile === 'boolean'
      ? banner.inversedMobile
      : banner.inversed;

  return (
    <CardInView
      {...restProps}
      id={banner.id}
      listTitle='menu_promotions'
      position={index}
      cardType='promo'
      card={banner}
      targetPathname={banner.link}
    >
      <Link to={banner.link} className={cnTemplate} onClick={onClickLink}>
        <div className={styles.container}>
          <Img className={styles.image} src={image} />

          <div
            className={cn(styles.text, {
              [styles.inversed]: inversed,
              [styles.full]: size === 'full',
              [styles.half]: size === 'half',
              [styles.leftTop]: alignmentX === 'left' && alignmentY === 'top',
              [styles.leftCenter]: alignmentX === 'left' && alignmentY === 'center',
              [styles.centerTop]: alignmentX === 'center' && alignmentY === 'top',
              [styles.centerCenter]: alignmentX === 'center' && alignmentY === 'center',
              [styles.sameAlignment]: isDesktop,
            })}
          >
            {text.period && (
              <Text
                className={styles.period}
                desktop={text.period}
                mobile={text.periodMobile}
                isMobile={isDesktop}
              />
            )}

            <Text
              className={styles.title}
              desktop={text.title}
              mobile={text.titleMobile}
              isMobile={isDesktop}
            />

            {text.subtitle && (
              <Text
                className={styles.subtitle}
                desktop={text.subtitle}
                mobile={text.subtitleMobile}
                isMobile={isDesktop}
              />
            )}

            <Text className={styles.link} desktop={text.link} />

            {needTimer && !isDesktop && (
              <Timer
                className={styles.timer}
                textBeforeTimer={text.timer}
                textAfterTimer={text.afterTimer}
                endDate={new Date(promotion.endDate)}
              />
            )}
          </div>
        </div>
      </Link>

      {needTimer && isDesktop && (
        <Timer
          className={styles.timer}
          view='inline'
          textBeforeTimer={text.timer}
          textAfterTimer={text.afterTimer}
          endDate={new Date(promotion.endDate)}
        />
      )}
    </CardInView>
  );
};

export default PromotionTemplate;
