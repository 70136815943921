import { useQuery } from '@tanstack/react-query';

import useSearchParams from '@Hooks/useSearchParams';
import useDeps from '@Contexts/DI/useDeps';
import Api from '@Api/DeliveryTracking';

import type { UseQueryResult } from '@tanstack/react-query';
import type { OrderState } from '@Types/DeliveryTracking';
import type { OrderStateDTO } from '@Api/DeliveryTracking/typings';

const refetchInterval = 60000;

const useOrderState = (): UseQueryResult<OrderState> => {
  const { logger } = useDeps();
  const id = useSearchParams().get('id');

  const keys = ['delivery-order-state', id];

  const getOrderState = async () => {
    try {
      const orderState = await Api.getOrderState({ id });

      return orderState;
    } catch (err) {
      logger.error(err);
    }
  };

  const result = useQuery<OrderStateDTO, Error, OrderState>({
    queryKey: keys,
    queryFn: getOrderState,
    placeholderData: (previousData) => previousData,
    refetchInterval(query) {
      const status = query.state.data?.status;

      if (status !== 'completed' && status !== 'canceled') return refetchInterval;

      return false;
    },
    select(data) {
      if (!data) return {} as OrderState;

      return {
        status: data.status,
        orderCode: data.order_code,
        deliveryCode: data.delivery_task_id,
        deliveryTimeFrom: data.delivery_time_from,
        deliveryTimeTo: data.delivery_time_to,
        deliveryDate: data.delivery_task_date,
        courierId: data.courier_id,
        courierPhone: data.courier_phone,
        supportPhone: data.support_phone,
      };
    },
  });

  return result;
};

export default useOrderState;
