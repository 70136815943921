import { useEffect } from 'react';
import { useFlags } from 'flagsmith/react';

import clarity from './clarity';

import type { FC } from 'react';

export interface ClarityTrackerConfig {
  projectKey?: string;
  userId?: string;
}

const TrackerComponent: FC<ClarityTrackerConfig> = () => {
  const { clarity: clarityState } = useFlags(['clarity']);
  const isEnabled = clarityState.enabled;
  const projectKey = isEnabled && JSON.parse(clarityState.value as string)?.projectKey;

  useEffect(() => {
    if (isEnabled && projectKey) {
      clarity.init(projectKey);
    }
  }, [isEnabled, projectKey]);

  return null;
};

export default TrackerComponent;
