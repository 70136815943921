import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import useRequest from '@Hooks/useRequest';
import useNavigation from '@Navigation/useNavigation';

export function getPluralForm(n: number, lang: string) {
  const m = Math.abs(n);
  let form: string;

  switch (lang) {
    case 'ru':
      if (m % 10 === 1 && m % 100 !== 11) {
        form = 'one';
      } else if (m % 10 >= 2 && m % 10 <= 4 && (m % 100 < 10 || m % 100 >= 20)) {
        form = 'few';
      } else {
        form = 'other';
      }
      break;
    default:
      if (m === 1) {
        form = 'one';
      } else {
        form = 'other';
      }
      break;
  }

  return form;
}

const usePageTranslation = () => {
  const { pathname } = useNavigation();
  const { country, language, queryClient } = useRequest();
  const params = { country, lang: language.id, pathname };
  const keys = ['translation', 'page', params];

  return useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData(keys);

      if (cache) return Promise.resolve(cache);

      return ApiMeta.getPageTranslation(params);
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });
};

const useUITranslation = () => {
  const { country, language } = useRequest();
  const params = { lang: language.id, country };

  return useQuery({
    queryKey: ['translation', 'ui', params],
    queryFn: () => {
      return ApiMeta.getUITranslation(params);
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });
};

const useTranslation = () => {
  const { language } = useRequest();
  const page = usePageTranslation();
  const ui = useUITranslation();

  function ref(row: any, key: string): string | undefined {
    key.split('.').forEach((k) => (row ? (row = row[k]) : undefined));

    return row;
  }

  function t(key: string, count?: number) {
    const data = key.startsWith('ui.') ? ui.data : page.data;
    let modifiedKey: string;
    if (count !== undefined) {
      const pluralForm = getPluralForm(count, language.id);
      modifiedKey = `${key}.${pluralForm}`;
    } else {
      modifiedKey = key;
    }
    const value = ref(data, modifiedKey);

    return value === '' ? '' : value || modifiedKey;
  }

  return { t, isError: page.isError || ui.isError, isPending: page.isPending || ui.isPending };
};

export default useTranslation;
