import useRequest from '@Hooks/useRequest';
import * as Profile from '@Queries/Profile';

// Возвращает ключи кэширования для корзины
function useCartKeys() {
  const { region, language } = useRequest();
  const profile = Profile.useProfile();

  return ['cart', { region, lang: language.id, user: profile.data?.mindboxId }];
}

export default useCartKeys;
