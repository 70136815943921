import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';
import useDeps from '@Contexts/DI/useDeps';
import useRequest from '@Hooks/useRequest';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */
function useCompareList() {
  const { region, language } = useRequest();
  const { eventManager } = useDeps();
  const keys = ['compare', region, language];

  const query = useQuery({
    queryKey: keys,
    queryFn: ApiCompare.getCompareProducts,
    placeholderData: (previousData) => previousData,
    select(data) {
      return {
        ...data,
        count: data.products?.length,
      };
    },
  });

  useEffect(() => {
    if (!query.data) return;

    eventManager.dispatch('compareProducts.load', query.data);
  }, [eventManager, query.data]);

  return query;
}

export default useCompareList;
