import { useMutation, useQueryClient } from '@tanstack/react-query';

import useInfoStripe from '@Queries/InfoStripe/useInfoStripe';
import setCookie from '@Utils/setCookie';
import * as HeaderStore from '@Stores/Header';
import useDeps from '@Contexts/DI/useDeps';
import * as ApiMeta from '@Api/Meta';

import type { QueryClient } from '@tanstack/react-query';

const closeTopline = (
  queryClient: QueryClient,
  queryInfoStripe: ReturnType<typeof useInfoStripe>,
  analytics: ReturnType<typeof useDeps>['analytics'],
): void => {
  const infoStripe = queryInfoStripe?.data;

  if (!infoStripe) return;

  setCookie(infoStripe.cookie, '1', { 'max-age': infoStripe.cookieTime });
  HeaderStore.setSizes({ toplineHeight: 0 });

  queryClient.removeQueries({ queryKey: ['info-stripe'] });

  analytics.dispatchEvent('topline.close', {
    text: infoStripe.text,
  });
};

const useCloseInfoStripe = () => {
  const queryClient = useQueryClient();
  const { analytics, logger } = useDeps();
  const queryInfoStripe = useInfoStripe();

  const mutation = useMutation({
    mutationFn: () => {
      return ApiMeta.closeInfoStripe();
    },
    onMutate: () => {
      closeTopline(queryClient, queryInfoStripe, analytics);
    },
    onError: (e) => {
      logger.error(e);
    },
  });

  return mutation;
};

export default useCloseInfoStripe;
