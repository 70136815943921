import { useCallback, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import validationSchema from './schema';
import * as ModalWindows from '@Stores/ModalWindows';
import useTranslation from '@Queries/useTranslation';
import useDeps from '@Contexts/DI/useDeps';
import Input from '@UI/Input';
import Form from '@UI/Form';
import styles from './SubscriptionForm.module.css';

import type { FormProps } from '@UI/Form';
import type { FC, FormEvent } from 'react';

export interface SubscriptionFormData {
  email: string;
}

export interface SubscriptionFormProps extends Omit<FormProps<SubscriptionFormData>, 'children'> {
  className?: string;
  source: string;
}

const SubscriptionForm: FC<SubscriptionFormProps> = (props) => {
  const { className, source, ...restProps } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const { analytics } = useDeps();

  const handleSubmit = useCallback(
    (e: FormEvent, data: SubscriptionFormData) => {
      analytics.dispatchEvent('forms', {
        formName: 'subscribe.rubrics',
        data: { ...data, source: source },
      });

      ModalWindows.open('Info', { view: 'success', variant: 'email-subscribe' });

      setValue('');
      const input = inputRef.current;
      if (input) {
        input.blur();
      }
    },
    [source, analytics],
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <Form
      {...restProps}
      className={cn(styles.form, { [styles.focused]: focused }, className)}
      method='POST'
      name='subscription'
      validationSchemaBuilder={validationSchema}
      onSubmit={handleSubmit}
    >
      <div className={styles.wrapper}>
        <Input
          ref={inputRef}
          wide
          rounded
          value={value}
          defaultValue={''}
          clear={false}
          view='plain'
          type='text'
          placeholder={t('ui.form-fields.email')}
          name='email'
          after={
            <button className={styles.button} type='submit'>
              {t('ui.send')}
              <IconChevronRight className={styles.icon} />
            </button>
          }
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
    </Form>
  );
};

export default SubscriptionForm;
