import { useState } from 'react';

import RequestContext from './RequestContext';

import type { FC, PropsWithChildren } from 'react';
import type { RequestState } from './RequestContext';

export interface RequestProviderProps {
  value: RequestState;
}

const RequestProvider: FC<PropsWithChildren<RequestProviderProps>> = (props) => {
  const { value, children } = props;
  const [innerValue, updateRequest] = useState(value);

  return (
    <RequestContext.Consumer>
      {(context) => (
        <RequestContext.Provider value={{ ...innerValue, ...context, updateRequest }}>
          {children}
        </RequestContext.Provider>
      )}
    </RequestContext.Consumer>
  );
};

export default RequestProvider;
