import { createStore, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

interface CurrentDate {
  currentDate: Date;
  timeDifference: number;
}

export const currentDate = createStore<CurrentDate>(() => {
  currentDate.set({
    currentDate: new Date(),
    timeDifference: 0,
  });
});

export function setCurrentDate(date: Date) {
  update(currentDate, () => ({
    currentDate: date,
    timeDifference: +date - Date.now(),
  }));
}

export function useDevPanel() {
  const date = useStore(currentDate);
  const currentDateInSec = Math.floor(+currentDate.value.currentDate / 1000).toString();

  return { ...date, currentDateInSec };
}

export function getCurrentDateInSec() {
  if (currentDate.value?.currentDate === undefined) {
    return Date.now();
  }
  return parseInt((+currentDate.value.currentDate / 1000).toString()).toString();
}
