import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { UpdatedCartInfo } from '@Types/Cart';

export interface Params {
  cartPositionId: string;
  quantity: number;
  signal?: AbortSignal;
}

export interface Result {
  result: 'success' | 'error';
  data: UpdatedCartInfo;
}

async function update(params: Params) {
  const { signal, ...bodyParams } = params;
  const url = '/backend/cart-2/update';
  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyParams),
    signal,
  });

  if (res.result !== 'success') throw new BackendError(url, res);

  return res.data;
}

export default update;
