import { memo } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import DI from '@Domain/di';

import type { ErrorInfo, FC, PropsWithChildren } from 'react';
import type { ErrorBoundaryProps as ReactErrorBoundaryProps } from 'react-error-boundary';

export interface ErrorBoundaryProps extends PropsWithChildren {
  fallback?: ReactErrorBoundaryProps['fallback'];
  onError?: ReactErrorBoundaryProps['onError'];
}

const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  const { children, fallback } = props;

  const onError = (error: Error, info: ErrorInfo) => {
    const { logger } = DI.getDeps();

    if (props && props.onError) props.onError(error, info);
    logger?.error(error);
  };

  return (
    <ReactErrorBoundary fallback={fallback || <></>} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default memo(ErrorBoundary);
