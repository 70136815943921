import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { CartData } from '@Types/Cart';

async function getCartInfo() {
  const url = '/backend/order/get-cart-info';
  const res = await Api.queryProxi<ApiResponse<CartData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getCartInfo;
