import cutOutRegionSlug from '@Utils/cutOutRegionSlug';

// Если в ключах query мы используем search парамерты,
// то при гидрации ключи могут отличаться, что приведет к ошибкам гидрации.
// Функция фильтрации параметров необходима для симметричной фильрации
// параметров сервиса кешиорвания, для избежания подобных ошибок.
export function filterSearchParams(searchString: string) {
  const forbiddenParams = [
    'query',
    'mdrv',
    '_ga',
    '_openstat',
    'gclsrc',
    'etext',
    'no_cache',
    'search_block',
  ];

  const forbiddenParamRegex = [
    '^(fb|g|y)clid',
    '^mindbox.*',
    '^(_bta|calltouch|hsa|matomo|mc|mtm|piwik|pk|rb|trk|utm)_.*',
  ];

  const searchParams = new URLSearchParams(searchString);
  const paramsArray = [];
  searchParams.forEach((_, param) => {
    paramsArray.push(param);
  });

  paramsArray.forEach((param) => {
    const hasRegex = forbiddenParamRegex.some((regex) => new RegExp(regex).test(param));
    const hasValue = forbiddenParams.includes(param);

    if (hasRegex || hasValue) {
      searchParams.delete(param);
    }
  });

  const search = searchParams.toString() ? `?${searchParams.toString()}` : searchParams.toString();

  return search;
}

const generateQueryKeys = ({
  keys,
  pathname,
  search,
}: {
  keys: string[];
  pathname: string;
  search: string;
}) => {
  const filteredSearch = filterSearchParams(search);

  return [...keys, cutOutRegionSlug(pathname), filteredSearch].filter(Boolean);
};

export default generateQueryKeys;
