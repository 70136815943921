import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

async function info() {
  const url = '/backend/cart-2/info';
  const res = await Api.queryProxi<any>(url);

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default info;
