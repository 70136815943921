import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import useRequest from '@Hooks/useRequest';
import useDeps from '@Contexts/DI/useDeps';
import transformCart from './helpers/transformCart';
import useCartKeys from './useCartKeys';

import type { CartData, CartPositionData, CartProductData } from '@Types/Cart';

export interface Params {
  positionId: CartPositionData['id'];
  productId?: CartProductData['id'];
}

function useHidePosition() {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<CartData, Error, Params>({
    mutationFn: async (params) => {
      const { positionId, productId } = params;
      const res = await ApiCart.hide({ cartPositionId: positionId, shopProductId: productId });

      return transformCart({ cart: res });
    },
    onMutate: async ({ positionId }) => {
      await queryClient.cancelQueries({ queryKey: keys });

      // Снимок предыдущего значения
      const prevCart: CartData = queryClient.getQueryData(keys);

      const position = prevCart.positions.find((pos) => positionId === pos.id);
      //Если позиции нет в корзине ничего не делаем, возвращаем предыдущий контекст
      if (!position) return { prevCart };

      const cartPositions = prevCart.positions.filter((pos) => pos.id !== position.id);

      const newCart = {
        ...prevCart,
        positions: cartPositions,
        removedPositions: [...prevCart.removedPositions, position],
      };

      // Оптимистическое обновление
      queryClient.setQueryData(keys, () => {
        return newCart;
      });
      // Возвращаем объект контекста с зафиксированным значением
      return { prevCart };
    },
    onError: (err, data, context: { prevCart: CartData }) => {
      queryClient.setQueryData(keys, context.prevCart);
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(keys, (prev: CartData) => ({
        ...prev,
        ...cart,
      }));

      analytics.dispatchEvent('cart.update', { cart, removeLabel: 'Одиночное удаление' });
    },
  });
}

export default useHidePosition;
